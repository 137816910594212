.row-container {
  height: 95vh;
  /* margin-top: 15%; */
}
.right-side h1 {
  font-weight: 900;
  font-size: 75px;
  color: #fff;
  text-shadow: 0px 1px 0px rgb(136, 136, 136), 0px 2px 0px rgb(95, 95, 95),
    0px 3px 0px rgb(56, 56, 56), 0px 4px 0px rgb(56, 56, 56),
    0px 5px 0px rgb(56, 56, 56), 0px 6px 0px rgb(56, 56, 56), 0px 7px 0px #333,
    0px 8px 7px #0000008f;
  font: 80px;
}
.right-side p {
  font-weight: 300;
}
.phone-dp {
  display: none;
}
.social-icon {
  color: white;
  font-size: 30px;
  padding-right: 20px;
  transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
}
.social-icon:hover {
  color: #ffc107;
}
/*============ BUTTON =========*/
a.animated-button.thar-three {
  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  padding: 15px 25px;
  font-size: 20px;
  border: 2px solid #ffc107;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
a.animated-button.thar-three:hover {
  color: #000 !important;
  background-color: transparent;
}
a.animated-button.thar-three:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
a.animated-button.thar-three:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background: #ffc107;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

.navbar {
  height: 100%;
  width: 50px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  /* background-color: rgba(17, 17, 17, 0.466); */
  overflow-x: hidden;
  padding-top: 20px;
  margin-right: 30px;
}
.phone-nav {
  display: none;
}
.nav-icon {
  font-size: 29px;
  padding: 0 10px 6px 10px;
  border-radius: 50%;
  color: rgb(31, 31, 31) !important;
  background-color: #ffc107;
}
/* .nav-icon:hover {
  color: #ff0728 !important;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
} */

/* ------------------------------------------------------------- */
@media only screen and (max-width: 633px) {
  .phone-dp {
    display: block;
  }
  .desk-dp {
    display: none;
  }
  .row-container {
    margin-top: 5%;
  }
  .right-side h1 {
    font-size: 55px;
  }
  .phone-nav {
    display: block;
    /* ----- */
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(69, 0, 160);
    color: white;
    text-align: center;
  }
  .fss-9 {
    font-size: 2.5rem !important;
  }
  .desk-nav {
    display: none;
  }
  .nav-icon {
    padding: 0 10px;
    border-radius: 0%;
    color: rgb(31, 31, 31) !important;
    background-color: #ffc107;
  }
}
/* ------------------------------------------------------------- */
/* @media only screen and (min-width: 1024px) {
  .row-container {
    margin-top: 5%;
  }
} */
