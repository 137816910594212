@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  color: white;
  background-color: rgb(32, 32, 32);
  font-family: "Outfit", sans-serif;
}
::-webkit-scrollbar {
  width: 0px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.about-section {
  margin-top: 5%;
  margin-bottom: 5rem;
}
.info h1 {
  font-weight: 600;
}
.info-left > h6,
.info-right > h6 {
  font-weight: 300;
}
.page-title {
  font-weight: 900;
  font-size: 75px;
  margin-bottom: 5rem;
}
.feedback {
  border: 1px solid gray;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
}
.languages-skills {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;
  margin: 0 auto;
}
.skill {
  background-color: rgb(90, 90, 90);
  border-radius: 10px;
  padding: 30px;
  text-align: center;
}

/* ----------------------------------------- */
@media only screen and (max-width: 633px) {
  .page-title {
    font-weight: 900;
    font-size: 75px;
    margin-bottom: 2rem;
  }
  .info-left > h6,
  .info-right > h6 {
    font-size: 15px;
    font-weight: 300;
  }
  .languages-skills {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 0 auto;
  }
}

.blog {
  margin-top: 20%;
}

input {
  width: 100%;
  height: 60px;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid gray;
  background-color: #202020;
  color: white;
}
textarea {
  width: 100%;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid gray;
  background-color: #202020;
  color: white;
}

.row-container {
  height: 95vh;
  /* margin-top: 15%; */
}
.right-side h1 {
  font-weight: 900;
  font-size: 75px;
  color: #fff;
  text-shadow: 0px 1px 0px rgb(136, 136, 136), 0px 2px 0px rgb(95, 95, 95),
    0px 3px 0px rgb(56, 56, 56), 0px 4px 0px rgb(56, 56, 56),
    0px 5px 0px rgb(56, 56, 56), 0px 6px 0px rgb(56, 56, 56), 0px 7px 0px #333,
    0px 8px 7px #0000008f;
  font: 80px;
}
.right-side p {
  font-weight: 300;
}
.phone-dp {
  display: none;
}
.social-icon {
  color: white;
  font-size: 30px;
  padding-right: 20px;
  transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1);
}
.social-icon:hover {
  color: #ffc107;
}
/*============ BUTTON =========*/
a.animated-button.thar-three {
  color: #fff;
  cursor: pointer;
  display: block;
  position: relative;
  padding: 15px 25px;
  font-size: 20px;
  border: 2px solid #ffc107;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
a.animated-button.thar-three:hover {
  color: #000 !important;
  background-color: transparent;
}
a.animated-button.thar-three:hover:before {
  left: 0%;
  right: auto;
  width: 100%;
}
a.animated-button.thar-three:before {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 0px;
  z-index: -1;
  content: "";
  color: #000 !important;
  background: #ffc107;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}

.navbar {
  height: 100%;
  width: 50px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  /* background-color: rgba(17, 17, 17, 0.466); */
  overflow-x: hidden;
  padding-top: 20px;
  margin-right: 30px;
}
.phone-nav {
  display: none;
}
.nav-icon {
  font-size: 29px;
  padding: 0 10px 6px 10px;
  border-radius: 50%;
  color: rgb(31, 31, 31) !important;
  background-color: #ffc107;
}
/* .nav-icon:hover {
  color: #ff0728 !important;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
} */

/* ------------------------------------------------------------- */
@media only screen and (max-width: 633px) {
  .phone-dp {
    display: block;
  }
  .desk-dp {
    display: none;
  }
  .row-container {
    margin-top: 5%;
  }
  .right-side h1 {
    font-size: 55px;
  }
  .phone-nav {
    display: block;
    /* ----- */
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(69, 0, 160);
    color: white;
    text-align: center;
  }
  .fss-9 {
    font-size: 2.5rem !important;
  }
  .desk-nav {
    display: none;
  }
  .nav-icon {
    padding: 0 10px;
    border-radius: 0%;
    color: rgb(31, 31, 31) !important;
    background-color: #ffc107;
  }
}
/* ------------------------------------------------------------- */
/* @media only screen and (min-width: 1024px) {
  .row-container {
    margin-top: 5%;
  }
} */

.my-projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  max-width: 100%;
}
.project {
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 25px;
  color: black;
}
.project > p {
  display: none;
}
.project img {
  border-radius: 20px;
  border: 2px solid #202020a1;
}
.view-links {
  text-align: center;
}
.view-links a {
  text-decoration: none;
  margin-right: 15px;
  font-size: 20px;
  color: aliceblue;
  padding: 3px 25px 6px 25px;
  border-radius: 5px;
  background-color: rgb(37, 37, 37);
}
.view-links a:hover {
  color: #ffc107;
}
.technology {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3px;
  text-align: center;
  margin: 10px 0;
}
.technology p {
  background-color: rgb(37, 37, 37);
  padding: 2px 10px;
  border-radius: 3px;
  color: white;
}
/* ----------------------------------------- */
@media only screen and (max-width: 633px) {
  .portfolio-section > h1 {
    font-size: 50px;
  }
  .my-projects {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* max-width: 100%; */
    margin: 0 auto;
    margin-bottom: 5rem;
  }
  .project {
    text-align: center;
    background-color: #8ba3b8;
    padding: 20px;
    border-radius: 15px;
    color: black;
  }
  .project > p {
    display: none;
  }
  .project img {
    width: 100%;
  }
  .technology {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3px;
    text-align: center;
    margin: 10px 0;
  }
}



.project-material {
  background-color: aliceblue;
  padding: 30px;
  margin-bottom: 4rem;
}
.detail {
  background-color: rgb(240, 184, 0);
  color: black !important;
  padding: 30px;
  margin-bottom: 5rem;
}

