.project-material {
  background-color: aliceblue;
  padding: 30px;
  margin-bottom: 4rem;
}
.detail {
  background-color: rgb(240, 184, 0);
  color: black !important;
  padding: 30px;
  margin-bottom: 5rem;
}
