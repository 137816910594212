input {
  width: 100%;
  height: 60px;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid gray;
  background-color: #202020;
  color: white;
}
textarea {
  width: 100%;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid gray;
  background-color: #202020;
  color: white;
}
