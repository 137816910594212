.about-section {
  margin-top: 5%;
  margin-bottom: 5rem;
}
.info h1 {
  font-weight: 600;
}
.info-left > h6,
.info-right > h6 {
  font-weight: 300;
}
.page-title {
  font-weight: 900;
  font-size: 75px;
  margin-bottom: 5rem;
}
.feedback {
  border: 1px solid gray;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
}
.languages-skills {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 15px;
  margin: 0 auto;
}
.skill {
  background-color: rgb(90, 90, 90);
  border-radius: 10px;
  padding: 30px;
  text-align: center;
}

/* ----------------------------------------- */
@media only screen and (max-width: 633px) {
  .page-title {
    font-weight: 900;
    font-size: 75px;
    margin-bottom: 2rem;
  }
  .info-left > h6,
  .info-right > h6 {
    font-size: 15px;
    font-weight: 300;
  }
  .languages-skills {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 0 auto;
  }
}
