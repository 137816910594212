.my-projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
  max-width: 100%;
}
.project {
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 25px;
  color: black;
}
.project > p {
  display: none;
}
.project img {
  border-radius: 20px;
  border: 2px solid #202020a1;
}
.view-links {
  text-align: center;
}
.view-links a {
  text-decoration: none;
  margin-right: 15px;
  font-size: 20px;
  color: aliceblue;
  padding: 3px 25px 6px 25px;
  border-radius: 5px;
  background-color: rgb(37, 37, 37);
}
.view-links a:hover {
  color: #ffc107;
}
.technology {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3px;
  text-align: center;
  margin: 10px 0;
}
.technology p {
  background-color: rgb(37, 37, 37);
  padding: 2px 10px;
  border-radius: 3px;
  color: white;
}
/* ----------------------------------------- */
@media only screen and (max-width: 633px) {
  .portfolio-section > h1 {
    font-size: 50px;
  }
  .my-projects {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* max-width: 100%; */
    margin: 0 auto;
    margin-bottom: 5rem;
  }
  .project {
    text-align: center;
    background-color: #8ba3b8;
    padding: 20px;
    border-radius: 15px;
    color: black;
  }
  .project > p {
    display: none;
  }
  .project img {
    width: 100%;
  }
  .technology {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3px;
    text-align: center;
    margin: 10px 0;
  }
}
